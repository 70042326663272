/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // toggles hamburger and nav open and closed states
          var removeClass = true;
            $(".hamburger").click(function () {
            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
          });

          $(".sideNav").click(function() {
            removeClass = false;
          });

          document.addEventListener('touchstart', function() {
            if (removeClass) {
               $(".hamburger").removeClass('is-active');
               $("#sideNav").removeClass('sideNav-open');
               $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
          }, false);


         $('#imageGallery').lightSlider({
              gallery:true,
              item:1,
              enableDrag: false,
              currentPagerPosition:'left',
              pager:false,
              controls: false,
              onSliderLoad: function(el) {
                  el.lightGallery({
                      selector: '#imageGallery .lslide'
                  });
              }
          });


          $("#launchGallery").click(function () { $('ul#imageGallery li:first').click(); });

        // dismiss site alert
            // if (!Cookies.get('alert')) {
            if( typeof $(".site-notice") !== 'undefined'){
                $( ".site-notice" ).fadeIn();
            }
                // $(".close").click(function() {
                //     $(this).parents('#siteNotice').fadeOut();
                //     // set the cookie for 24 hours
                //     var date = new Date();
                //     date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                //     Cookies.set('alert', true, { expires: date, path: '/' });
                // });
            // }

          // Scroll Up Button
          $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
              $('.scrollup').fadeIn();
            } else {
              $('.scrollup').fadeOut();
            }
          });

          $('.scrollup').click(function () {
            $("html, body").animate({
              scrollTop: 0
            }, 600);
            return false;
          });


          // Test Screen Size
          var screenSize;

            function checkSize() {
              if($('.test-size').css('font-size') === '1px') {
                screenSize = 'xs';
              } else if($('.test-size').css('font-size') === '2px') {
                screenSize = 'sm';
              } else if($('.test-size').css('font-size') === '3px') {
                screenSize = 'md';
              } else if($('.test-size').css('font-size') === '4px') {
                screenSize = 'lg';
              }
            }

            checkSize();

            $(window).resize(function(){
              checkSize();
            });

            function isScrolledIntoView(elem)
            {
              var docViewTop = $(window).scrollTop();
              var docViewBottom = docViewTop + $(window).height();

              var elemTop = $(elem).offset().top;
              var elemBottom = elemTop + $(elem).height();
              return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop) && docViewTop !== 0);
            }

            var showSwipe = false;

            $(window).scroll(function() {
              if($('.swipicon-container').length) {
                if(isScrolledIntoView($('.swipicon-container')) && !$('.swipicon').hasClass('swipiconActive') && !showSwipe && (screenSize === 'xs' || screenSize === 'sm')) {
                  showSwipe = true;
                  $('.swipicon').fadeIn(1500,function(){
                    $('.swipicon').addClass('swipiconActive');
                  }).delay(1600).fadeOut(1500);
                }
              }
            });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Contact us page
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the contact us page

        function initialize_gmap(point_lat, point_long, address, title, directions) {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          var isDraggable = w > 480 ? true : false;
          var point = new google.maps.LatLng(point_lat, point_long);
          var map_lat = point_lat + 0.002812;

          var settings = {
            zoom: 15,
            center: {lat: map_lat, lng: point_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: true,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: true,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP};

          var map = new google.maps.Map(document.getElementById("map_canvas"), settings);

          var contentString = '<div id="gmapContent" style="text-align:left;">'+
            '<a href="' + directions + '" target="_blank"><h3>' + title + '<br><small>(Click for directions)</small></h3></a>'+
            '<div id="bodyContent">'+
            '<p>' + address + '</p>'+
            '</div>'+
            '</div>';

          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });

          var companyMarker = new google.maps.Marker({
            position: point,
            map: map,
            title: title});

          infowindow.open(map,companyMarker);

          google.maps.event.addDomListener(window, "resize", function() {
             var center = map.getCenter();
             google.maps.event.trigger(map, "resize");
             map.setCenter(center);
           });
        }

        if($('#map_canvas').length) {
          var point_lat = parseFloat($('.map-data .point_lat').data('pointlat'));
          var point_long = parseFloat($('.map-data .point_long').data('pointlong'));
          var address = $('.map-data .address').data('address');
          var title = $('.map-data .title').data('title');
          var directions = $('.map-data .directions').data('directions');
          initialize_gmap(point_lat, point_long, address, title, directions);
        }


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
